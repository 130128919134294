import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'

export function useGeolocation() {
  return useAuthCallPromise(
    (token: string) => (data: any) =>
      axios
        .post(`/api/admin/geocode/`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => r.data)
  )
}
