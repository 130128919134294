import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import KeywordsList from './KeywordsList'
import KeywordsCreate from './KeywordsCreate'
import KeywordsEdit from './KeywordsEdit'

export default function Keywords() {
  return (
    <Routes>
      <Route index element={<KeywordsList />} />
      <Route path="new" element={<KeywordsCreate />} />
      <Route path=":id" element={<KeywordsEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
