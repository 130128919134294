import classNames from 'classnames'
import { FastField, Field, Formik } from 'formik'
import { useState } from 'react'
import { useAuthUser } from 'use-eazy-auth'
import * as Yup from 'yup'
import { EMPTY_LANG_FIELD, LANGS, LANGS_WITHOUT_DEFAULT } from '../consts'
import { BaseContent, Content, User } from '../types'
import {
  convertRichTranslatedFieldToPlainText,
  InputField,
  RichTextField,
  transformErrorForForm,
} from './form'

const initialValues: BaseContent = {
  text: EMPTY_LANG_FIELD,
  text_plain: EMPTY_LANG_FIELD,
  code: '',
  label: EMPTY_LANG_FIELD,
}

const ContentSchema = Yup.object().shape({
  code: Yup.string().required().label('Codice'),
  label: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Titolo')]))
  ),
  text: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Testo')]))
  ),
})

interface Props {
  content?: Content
  save: (content: Content) => Promise<unknown>
}

export default function ContentFrom({ save, content }: Props) {
  const { user } = useAuthUser<User>()
  const [lang, setLang] = useState('en')
  return (
    <Formik
      validationSchema={ContentSchema}
      onSubmit={(content, { setErrors }) =>
        save({
          ...content,
          text_plain: convertRichTranslatedFieldToPlainText(content.text),
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(content ?? initialValues) as Content}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div className="d-flex">
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-12">
              <Field
                disabled={!user!.is_staff}
                label="Codice"
                name="code"
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Titolo [it]"
                name="label.it"
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Titolo [${lang}]`}
                name={`label.${lang}`}
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <FastField
                label={`Testo [it]`}
                name={`text.it`}
                component={RichTextField}
              />
            </div>
            <div className="col-md-6">
              <FastField
                label={`Testo [${lang}]`}
                name={`text.${lang}`}
                component={RichTextField}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
