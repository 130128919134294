import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import BlockHomePageCreate from './BlockHomePageCreate'
import BlocksHomePageEdit from './BlocksHomePageEdit'
import BlocksHomePageList from './BlocksHomePageList'

export default function BlocksHomePage() {
  return (
    <Routes>
      <Route index element={<BlocksHomePageList />} />
      <Route path="new" element={<BlockHomePageCreate />} />
      <Route path=":id" element={<BlocksHomePageEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
