import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import MissingTranslations from '../../components/MissingTranslations'
import Page from '../../components/Page'
import Paginator from '../../components/Paginator'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { MAIN_LANG } from '../../consts'
import { useDeleteExtra, useExtras } from '../../hooks/extra'
import { useQsFilters } from '../../hooks/filters'
import { Extra } from '../../types'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? '',
})

const MISSING_TRANSLATIONS_FIELDS = ['title']

export default function ExtrasList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data } = useExtras(filters)
  const deleteExtra = useDeleteExtra()
  const [modalRm, modalRmActions] = useModalTrigger<Extra>()

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Extra</h2>
          <div className="counter ms-2 mb-1">
            <small>
              {data!.count}
              {' / '}
              {data!.total_count}
            </small>
          </div>
        </div>
        <Link className="btn btn-primary" to="/extras/new">
          <i className="bi bi-plus-circle me-2"></i> Aggiungi
        </Link>
      </Header>
      <div className="d-flex justify-content-between mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
      </div>
      <StickyTable className="flex-1">
        <thead>
          <Sorter
            value={uiFilters.ordering}
            onSortChange={(ordering) =>
              setFilters({
                ordering,
                page: 1,
              })
            }
          >
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Titolo
                  <SortControl field="title__it" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Data
                  <SortControl field="date_from" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Tipo
                  <SortControl field="type" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">Immagine</div>
              </th>
              <th></th>
            </tr>
          </Sorter>
        </thead>
        <tbody>
          {data!.results.map((extra) => (
            <tr key={extra.id}>
              <td>{extra.id}</td>
              <td>
                <Link to={`/extras/${extra.id}`}>{extra.title[MAIN_LANG]}</Link>
                <MissingTranslations
                  data={extra}
                  fields={MISSING_TRANSLATIONS_FIELDS}
                />
              </td>
              <td>
                {extra.date_from &&
                  format(new Date(extra.date_from), 'dd/MM/yyyy')}
              </td>
              <td>
                {extra.type === 'evento'
                  ? 'Evento'
                  : extra.type === 'contenuto_speciale'
                  ? 'Contenuto speciale'
                  : 'Approfondimento'}
              </td>
              <td>
                <img
                  style={{ width: 100 }}
                  src={extra.cover_image ?? ''}
                  alt={extra.title[MAIN_LANG]}
                />
              </td>
              {/* <td>{extra.category_data?.title[MAIN_LANG]}</td> */}
              <td>
                {/* show link preview */}
                <i
                  className="bi cursor-pointer text-primary bi-eye-fill me-2"
                  onClick={() =>
                    window.open(
                      'https://www.memoryscapes.it/it/extra/' + extra.id
                    )
                  }
                ></i>
                <i
                  onClick={() => modalRmActions.open(extra)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare l'extra{' '}
              {modalRm.value.title[MAIN_LANG]} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteExtra.isLoading}
            onClick={async () => {
              await deleteExtra.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <div className="position-relative">
        <Paginator
          count={data!.count}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </Page>
  )
}
