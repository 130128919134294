import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import { BaseKeyword, Keyword } from '../types'
import {
  InputField,
  transformErrorForForm,
} from './form'

const KeywordSchema = Yup.object().shape({
  value: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Keyword'
        ),
      ])
    )
  ),
})

interface Props {
  keyword?: Keyword
  save: (keyword: Keyword) => Promise<unknown>
}

export const initialThemeValues: BaseKeyword = {
  value: EMPTY_LANG_FIELD,
}

export default function KeywordForm({ keyword, save }: Props) {
  const [lang, setLang] = useState('en')

  return (
    <Formik
      enableReinitialize
      onSubmit={(keyword, { setErrors }) =>
        save(keyword).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={KeywordSchema}
      initialValues={(keyword ?? initialThemeValues) as Keyword}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div>
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Keyword [it]`}
                name={`value.it`}
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Keyword [${lang}]`}
                name={`value.${lang}`}
                component={InputField}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
