import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './RichTextField.css'
import { FieldProps } from 'formik'
import { useState } from 'react'

interface BlockTypeSelectorProps {
  currentState: { blockType: string }
  config: {
    options: string[]
  }
  onChange(e: any): void
  translations: Record<string, string>
}

function BlockTypeSelector({
  currentState,
  onChange,
  config,
  translations,
}: BlockTypeSelectorProps) {
  return (
    <div>
      <select
        onMouseDown={(e) => {
          e.stopPropagation()
          // Fuck Off....
        }}
        onChange={(e) => onChange(e.target.value)}
        value={currentState.blockType}
        className="form-select form-select-sm rich-text-select"
      >
        {config.options.map((o) => (
          <option key={o} value={o}>
            {translations[`components.controls.blocktype.${o.toLowerCase()}`]}
          </option>
        ))}
      </select>
    </div>
  )
}

type RichTextFieldProps = FieldProps & {
  label?: string
}

export default function RichTextField({ field, form, label }: RichTextFieldProps) {
  const [defaultEditorState] = useState(() => {
    const contentBlock = htmlToDraft(field.value ?? '')
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )
    return EditorState.createWithContent(contentState)
  })

  return (
    <div>
      {label && <label className='mb-1'>{label}</label>}
      <Editor
        wrapperClassName="hm-rich-text mb-2"
        editorClassName="hm-rich-text-editor"
        toolbarClassName="hm-rch-text-toolbar"
        handlePastedText={() => false}
        toolbar={{
          options: [
            'inline',
            'blockType',
            // 'fontSize',
            // 'fontFamily',
            // 'list',
            'textAlign',
            // 'colorPicker',
             'link',
            // 'embedded',
            // 'emoji',
            'list',
            // 'image',
            // 'remove',
            'history',
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          blockType: {
            inDropdown: true,
            options: [
              'Normal',
              'H1',
              'H2',
              'H3',
              'H4',
              'H5',
              'H6',
              'Blockquote',
            ],
            className: undefined,
            component: BlockTypeSelector,
            dropdownClassName: undefined,
          },
        }}
        defaultEditorState={defaultEditorState}
        onBlur={
          ((_: unknown, editorState: EditorState) => {
            const html = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            )
            form.setFieldValue(field.name, html)
          }) as never // Fuck Off bugged types
        }
      />
    </div>
  )
}
