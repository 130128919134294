import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import AuthorCreate from './AuthorCreate'
import AuthorEdit from './AuthorEdit'
import AuthorsList from './AuthorsList'

export default function Authors() {
  return (
    <Routes>
      <Route index element={<AuthorsList />} />
      <Route path="new" element={<AuthorCreate />} />
      <Route path=":id" element={<AuthorEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
