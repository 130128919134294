import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import KeywordForm from '../../components/KeywordForm'
import Page from '../../components/Page'
import { useCreateKeyword } from '../../hooks/keywords'

export default function KeywordsCreate() {
  const createKeyword = useCreateKeyword()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/keywords">Keywords</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <KeywordForm
          save={async (keyword) => {
            const savedCategory = await createKeyword.mutateAsync(keyword)
            navigate(`/keywords/${savedCategory.id}`)
          }}
        />
      </div>
    </Page>
  )
}
