import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import { useClips } from '../hooks/clip'
import { useStateFilters } from '../hooks/filters'
import { BaseSlide, Slide } from '../types'
import { smartSlug } from '../utils/slug'
import Footer from './Footer'
import { InputField, TextareaField, transformErrorForForm } from './form'
import Paginator from './Paginator'
import Sorter, { SortControl } from './Sorter'
import StickyTable from './StickyTable'

const SlideSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),
  description: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Descrizione')]))
  ),

  image: Yup.mixed().nullable().label('Immagine di copertina'),
})

interface Props {
  slide?: Slide
  save: (slide: Slide) => Promise<unknown>
}

export const initialSlideValues: Omit<BaseSlide, 'clip_data'> = {
  title: EMPTY_LANG_FIELD,
  description: EMPTY_LANG_FIELD,
  image: '',
  link: '',
  clip: null,
}

export default function SlideForm({ slide, save }: Props) {
  const [lang, setLang] = useState('en')
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useStateFilters({
      page: 1,
      ordering: '',
      search: '',
    })
  const { data } = useClips(filters)

  return (
    <Formik
      enableReinitialize
      onSubmit={(slide, { setErrors }) =>
        save(slide).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={SlideSchema}
      initialValues={(slide ?? initialSlideValues) as Slide}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue, setTouched }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <h4>Associa una clip</h4>
              <div className="d-flex align-items-center mt-2">
                <input
                  placeholder="Cerca"
                  className="form-control w-auto"
                  type="text"
                  value={uiFilters.search}
                  onChange={(e) =>
                    setFiltersDebounced({ search: e.target.value, page: 1 })
                  }
                />
              </div>
              <StickyTable className="flex-1 mt-3" style={{ maxHeight: 300 }}>
                <thead>
                  <Sorter
                    value={uiFilters.ordering}
                    onSortChange={(ordering) =>
                      setFilters({
                        ordering,
                        page: 1,
                      })
                    }
                  >
                    <tr>
                      <th></th>
                      <th>#</th>
                      <th>
                        <div className="d-flex align-items-center">
                          Titolo
                          <SortControl field="title__it" />
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          Autore
                          <SortControl field="author__name" />
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          Archivio
                          <SortControl field="archive" />
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          Formato
                          <SortControl field="format" />
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">Preview</div>
                      </th>
                    </tr>
                  </Sorter>
                </thead>
                <tbody>
                  {data!.results.map((clip) => (
                    <tr key={clip.id}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            if (values.clip) {
                              if (clip.id !== values.clip) {
                                setFieldValue('clip', clip.id)
                                setFieldValue('clip_data', clip)
                                setFieldValue('title.it', clip.title.it)
                                setFieldValue('title.en', clip.title.en)
                                setFieldValue(
                                  'description.it',
                                  clip.description.it
                                )
                                setFieldValue(
                                  'description.en',
                                  clip.description.en
                                )
                                setFieldValue(
                                  'link',
                                  `/it/clips/${smartSlug(
                                    clip.id,
                                    clip.title.it
                                  )}`
                                )
                              } else {
                                setFieldValue('clip', null)
                              }
                            } else {
                              setFieldValue('clip', clip.id)
                              setFieldValue('clip_data', clip)
                              setFieldValue('title.it', clip.title.it)
                              setFieldValue('title.en', clip.title.en)
                              setFieldValue(
                                'description.it',
                                clip.description.it
                              )
                              setFieldValue(
                                'description.en',
                                clip.description.en
                              )
                              setFieldValue(
                                'link',
                                `/it/clips/${smartSlug(
                                  clip.id,
                                  clip.title.it
                                )}`
                              )
                            }
                          }}
                          checked={values.clip === clip.id}
                        />
                      </td>
                      <td>{clip.id}</td>
                      <td>{clip.title.it}</td>
                      <td>{clip.author_data?.name}</td>
                      <td>{clip.archive}</td>
                      <td>{clip.format}</td>
                      <td>
                        {clip.image_thumb && (
                          <img
                            src={clip.image_thumb}
                            alt={clip.title.it}
                            height={50}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StickyTable>
              <Paginator
                count={data!.count}
                currentPage={uiFilters.page}
                goToPage={(page) => setFilters({ page })}
              />
            </div>
          </div>
          <hr />
          <div className="row">
            {values.clip_data && (
              <h4>{values.clip_data.title.it} (0-10 secondi)</h4>
            )}
            {values.clip_data && values.clip_data.file_url_vimeo && (
              <div className="col-md-12 w-100 d-flex justify-content-center">
                <video
                  key={values.clip_data.id}
                  controls
                  width={400}
                  src={`${values.clip_data.file_url_vimeo}#t=0,10`}
                />
              </div>
            )}
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div>
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Titolo [it]`}
                name={`title.it`}
                component={InputField}
              />
              <Field
                label={`Descrizione [it]`}
                name={`description.it`}
                rows={5}
                component={TextareaField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Titolo [${lang}]`}
                name={`title.${lang}`}
                component={InputField}
              />
              <Field
                label={`Descrizione [${lang}]`}
                name={`description.${lang}`}
                rows={5}
                component={TextareaField}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field label="Link" disabled component={InputField} name="link" />
            </div>
          </div>
          <Footer>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </Footer>
        </form>
      )}
    </Formik>
  )
}
