import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { useMemo, useState } from 'react'
import * as Yup from 'yup'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import { useAuthorsForSelect } from '../hooks/authors'
import { useFormats } from '../hooks/clip'
import { useKeywords } from '../hooks/keywords'
import { BaseBlockHome, BlockHome } from '../types'
import {
  DateField,
  InputField,
  MultiSelectField,
  TextareaField,
  transformErrorForForm,
} from './form'

const initialValues: BaseBlockHome = {
  title: EMPTY_LANG_FIELD,
  description: EMPTY_LANG_FIELD,
  authors: [],
  keywords: [],
  type: 'centered',
  formats: null,
  key: '',
  date_from: null,
  date_to: null,
  order: 0,
}

const BlockHomeSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),
  date_from: Yup.string().nullable().label('Data da'),
  date_to: Yup.string().nullable().label('Data a'),
  key: Yup.string().required().label('Chiave del blocco clips'),
  description: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Descrizione')]))
  ),
})

interface Props {
  block?: BlockHome
  save: (block: BlockHome) => Promise<unknown>
}

export default function BlockHomeForm({ save, block }: Props) {
  const [lang, setLang] = useState('en')
  const { data: authors } = useAuthorsForSelect()
  const { data: keywords } = useKeywords()
  const { data: formats } = useFormats()

  const keywordsToUse = useMemo(() => {
    return keywords?.map((keyword) => ({
      label: keyword.value.it,
      value: keyword.id,
    }))
  }, [keywords])

  const authorsToUse = useMemo(() => {
    return authors?.map((k) => ({
      value: k.id,
      label: k.name,
    }))
  }, [authors])

  const formatsToUse = useMemo(() => {
    return formats?.map((k) => ({
      value: k,
      label: k,
    }))
  }, [formats])

  return (
    <Formik
      validationSchema={BlockHomeSchema}
      onSubmit={(block, { setErrors }) =>
        save({
          ...block,
          date_from: block.date_from ? block.date_from : null,
          date_to: block.date_to ? block.date_to : null,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(block ?? initialValues) as BlockHome}
    >
      {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <div className="d-flex">
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Titolo [it]`}
                onChange={(e: any) => {
                  
                  setFieldValue('title.it', e.target.value)
                  const key = values.title.it.replace(/\s/g, '-').toLowerCase()
                  setFieldValue(
                    'key',
                    key
                  )
                }}
                name={`title.it`}
                component={InputField}
              />
              <Field
                label={`Descrizione [it]`}
                name={`description.it`}
                rows={5}
                component={TextareaField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Titolo [${lang}]`}
                name={`title.${lang}`}
                component={InputField}
              />
              <Field
                label={`Descrizione [${lang}]`}
                name={`description.${lang}`}
                rows={5}
                component={TextareaField}
              />
            </div>
            <div className="col-md-12">
              <Field
                name="keywords"
                component={MultiSelectField}
                label={'Keywords'}
                options={keywordsToUse}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="authors"
                component={MultiSelectField}
                label={'Authors'}
                options={authorsToUse}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="formats"
                component={MultiSelectField}
                label={'Formati'}
                options={formatsToUse}
              />
            </div>
            <div className="col-md-6">
              <Field name="date_from" label={'Data da'} component={DateField} />
            </div>
            <div className="col-md-6">
              <Field name="date_to" label={'Data a'} component={DateField} />
            </div>
            <div className="col-md-6">
              <Field name="order" component={InputField} label={'Ordine'} />
            </div>
            <div className="col-md-6">
              <label>Tipo blocco</label>
              <select
                onChange={(e) => setFieldValue('type', e.target.value)}
                value={values.type || ''}
                className="form-select"
              >
                <option value={'centered'}>Centrato</option>
                <option value={'not-centered'}>Non centrato</option>
              </select>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
