import { Field, Formik, getIn } from 'formik'
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'
import { BaseBlockVitaArchivio, BlockVitaArchivio, Clip } from '../types'
import { InputField, TextareaField, transformErrorForForm } from './form'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import classNames from 'classnames'
import * as Yup from 'yup'
import { Suspense, useState } from 'react'
import Paginator from './Paginator'
import StickyTable from './StickyTable'
import { useClips } from '../hooks/clip'
import { useStateFilters } from '../hooks/filters'
import Sorter, { SortControl } from './Sorter'

interface ClipPickerProps {
  clips: Clip[]
  setFieldValue(field: string, value: any): void
  error: string
}

function ClipsPicker({ clips, setFieldValue, error }: ClipPickerProps) {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useStateFilters({
      page: 1,
      ordering: '',
      search: '',
      document_type: '',
      content_type: '',
    })
  const { data } = useClips(filters)
  return (
    <div className="d-flex flex-column" style={{ maxHeight: 'calc(300px)' }}>
      <div className="d-flex align-items-center">
        <h5>Seleziona le clips</h5>
        {error && <div className="text-danger ms-3"> - {error}</div>}
      </div>
      <div className="d-flex align-items-center p-2">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({
              search: e.target.value,
              page: 1,
            })
          }
        />
      </div>
      <StickyTable className="mx-2 mt-2">
        <thead>
          <Sorter
            value={uiFilters.ordering}
            onSortChange={(ordering) =>
              setFilters({
                ordering,
                page: 1,
              })
            }
          >
            <tr>
              <th></th>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Titolo
                  <SortControl field={`title__${MAIN_LANG}`} />
                </div>
              </th>
              <th>Thumb</th>
            </tr>
          </Sorter>
        </thead>
        <tbody>
          {data!.results.map((clip) => (
            <tr key={clip.id}>
              <td>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('clips_data', [...clips, clip])
                    } else {
                      const clipsToPush =
                        clips && clips.filter((c) => c.id !== clip.id)
                      setFieldValue('clips_data', clipsToPush)
                    }
                  }}
                  checked={Boolean(
                    clips.filter((c) => c.id === clip.id).length > 0
                  )}
                />
              </td>
              <td>{clip.id}</td>
              <td>{clip.title[MAIN_LANG]}</td>
              <td>
                {clip.image_thumb && (
                  <img
                    style={{
                      height: 65,
                      width: 65,
                      objectFit: 'cover',
                    }}
                    alt="Thumb"
                    src={clip.image_thumb}
                    className="img-thumbnail"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Paginator
        count={data!.count}
        currentPage={uiFilters.page}
        goToPage={(page) => setFilters({ page })}
      />
    </div>
  )
}

interface Props {
  block?: BlockVitaArchivio
  save: (block: BlockVitaArchivio) => Promise<unknown>
  isOpen: boolean
  toggle?: (x: any) => void
  vita: number
}

const initialValues: BaseBlockVitaArchivio = {
  title: EMPTY_LANG_FIELD,
  description: EMPTY_LANG_FIELD,
  type: '',
  clips_data: [],
  order: 0,
  vita: null,
}

const BlockVitaArchivioSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),
  type: Yup.string().required(),
  clips_data: Yup.array().min(1, 'Una clip è richiesta').required('required'),
  description: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Descrizione')]))
  ),
})

export default function ModalBlockVitaArchivio({
  block,
  isOpen,
  toggle,
  save,
  vita,
}: Props) {
  const [lang, setLang] = useState('en')

  return (
    <Modal fullscreen isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {block ? block.title.it : 'Nuovo blocco contenuto'}
      </ModalHeader>

      <Formik
        initialValues={block ?? (initialValues as BlockVitaArchivio)}
        validationSchema={BlockVitaArchivioSchema}
        onSubmit={(values, { setErrors }) => {
          save({ ...values, vita }).catch((err) => {
            setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({
          isSubmitting,
          isValid,
          handleSubmit,
          errors,
          setFieldValue,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    {LANGS_WITHOUT_DEFAULT.map((language) => (
                      <div
                        key={language}
                        className={classNames({
                          'lang-active': language === lang,
                          'lang-non-active': language !== lang,
                        })}
                        onClick={() => setLang(language)}
                      >
                        {language}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label>
                    Tipo blocco <span className="text-danger">{' *'}</span>
                  </label>
                  <select
                    onChange={(e) => setFieldValue('type', e.target.value)}
                    value={values.type || ''}
                    className={classNames('form-select', {
                      'is-invalid': getIn(errors, 'type'),
                    })}
                  >
                    <option value={''}>Seleziona un tipo di blocco</option>
                    <option value={'timeline'}>Timeline</option>
                    <option value={'text-slider'}>Slider</option>
                  </select>
                  {getIn(errors, 'type') && (
                    <div className="text-danger">{getIn(errors, 'type')}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <Field
                    required
                    label={`Titolo [it]`}
                    name={`title.it`}
                    component={InputField}
                  />
                  {values.type !== 'timeline' && (
                    <Field
                      label={`Descrizione [it]`}
                      name={`description.it`}
                      rows={5}
                      component={TextareaField}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  <Field
                    label={`Titolo [${lang}]`}
                    name={`title.${lang}`}
                    component={InputField}
                  />
                  {values.type !== 'timeline' && (
                    <Field
                      label={`Descrizione [${lang}]`}
                      name={`description.${lang}`}
                      rows={5}
                      component={TextareaField}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  <Field
                    label={`Ordine`}
                    name={`order`}
                    component={InputField}
                  />
                </div>
                <hr />
                <Suspense
                  fallback={
                    <div className="w-100 text-center p-5">
                      <Spinner color="primary" />
                    </div>
                  }
                >
                  <ClipsPicker
                    clips={values?.clips_data || []}
                    setFieldValue={setFieldValue}
                    error={getIn(errors, 'clips_data')}
                  />
                </Suspense>
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <button
                type="button"
                onClick={toggle}
                disabled={isSubmitting}
                className="btn btn-outline-primary"
              >
                Annulla
              </button>
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
