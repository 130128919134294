import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      staleTime: 1000,
      cacheTime: 0,
      retry: false,
      suspense: true,
      structuralSharing: false,
    },
  },
})

export default function DataProvider({ children }: { children: JSX.Element }) {
  return (
    <QueryClientProvider client={client}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}
