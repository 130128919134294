import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import SlideForm from '../../components/SlideForm'
import { useCreateSlide } from '../../hooks/slides'

export default function SlideCreate() {
  const createSlide = useCreateSlide()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/slides-home">Slides Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <SlideForm
          save={async (slide) => {
            const savedSlide = await createSlide.mutateAsync(slide)
            navigate(`/slides-home/${savedSlide.id}`)
          }}
        />
      </div>
    </Page>
  )
}
