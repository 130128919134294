import { Link, useNavigate } from 'react-router-dom'
import ClipForm from '../../components/ClipForm'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateClip } from '../../hooks/clip'

export default function ClipCreate() {
  const createClip = useCreateClip()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/clips">Clips</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <ClipForm
          save={async (clip) => {
            const savedClip = await createClip.mutateAsync(clip)
            navigate(`/clips/${savedClip.id}`)
          }}
        />
      </div>
    </Page>
  )
}
