import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import { BaseSerieDetail, Serie } from '../types'
import Footer from './Footer'
import {
  FileField,
  InputField,
  TextareaField,
  transformErrorForForm,
} from './form'
import SortableRelatedClips from './SortableRelatedClips'
// import SortableRealatedSeries from './SortableRelatedSeries'

const SerieSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),
  description: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Descrizione')]))
  ),

  brand_text: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [l, Yup.string().label('Testo del brand')])
    )
  ),

  credits: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [l, Yup.string().label('Crediti serie')])
    )
  ),

  brand_logo: Yup.mixed().nullable().label('Logo del brand'),
  cover_image: Yup.mixed().nullable().label('Immagine di copertina'),
})

interface Props {
  serie?: Serie
  save: (serie: Serie) => Promise<unknown>
}

export const initialThemeValues: BaseSerieDetail = {
  title: EMPTY_LANG_FIELD,
  description: EMPTY_LANG_FIELD,
  description_home: EMPTY_LANG_FIELD,
  credits: EMPTY_LANG_FIELD,
  brand_text: EMPTY_LANG_FIELD,
  brand_logo: null,
  cover_image: null,
  related_clips_data: [],
  themes_data: [],
  themes_related: [],
  order: 0,
}

export default function SerieForm({ serie, save }: Props) {
  const [lang, setLang] = useState('en')

  return (
    <Formik
      enableReinitialize
      onSubmit={(serie, { setErrors }) =>
        save(serie).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={SerieSchema}
      initialValues={(serie ?? initialThemeValues) as Serie}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div>
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Titolo [it]`}
                name={`title.it`}
                component={InputField}
              />
              <Field
                label={`Descrizione [it]`}
                name={`description.it`}
                rows={5}
                component={TextareaField}
              />
              <Field
                label={`Descrizione Home [it]`}
                name={`description_home.it`}
                rows={3}
                component={TextareaField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Titolo [${lang}]`}
                name={`title.${lang}`}
                component={InputField}
              />
              <Field
                label={`Descrizione [${lang}]`}
                name={`description.${lang}`}
                rows={5}
                component={TextareaField}
              />
               <Field
                label={`Descrizione Home [en]`}
                name={`description_home.en`}
                rows={3}
                component={TextareaField}
              />
            </div>
            <div className="col-md-12">
              <Field label="Ordine" name="order" component={InputField} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4 mt-4">
              <h4>Informazioni aggiuntive</h4>
            </div>
            <div className="col-md-6">
              <Field
                label="Brand Logo"
                component={FileField}
                fileType="image"
                name="brand_logo"
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Immagine di copertina"
                component={FileField}
                fileType="image"
                name="cover_image"
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Crediti [it]`}
                name={`credits.it`}
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Crediti [${lang}]`}
                name={`credits.${lang}`}
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Brand Testo [it]"
                component={TextareaField}
                name="brand_text.it"
              />
            </div>

            <div className="col-md-6">
              <Field
                label={`Brand Testo [${lang}]`}
                name={`brand_text.${lang}`}
                component={TextareaField}
              />
            </div>
          </div>
          {/* {(!serie || serie?.themes_related.length === 0) && (
            <div>
              <SortableRealatedSeries excludeId={serie?.id} />
            </div>
          )} */}
          <div>
            <SortableRelatedClips />
          </div>
          <Footer>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </Footer>
        </form>
      )}
    </Formik>
  )
}
