import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import TerritorioForm from '../../components/TerritorioForm'
import { useCreateTerritorio } from '../../hooks/territories'

export default function TerritoryCreate() {
  const createTerritorio = useCreateTerritorio()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/territories">Territori</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <TerritorioForm
          save={async (territorio) => {
            const savedTerritorio = await createTerritorio.mutateAsync(territorio)
            navigate(`/territories/${savedTerritorio.id}`)
          }}
        />
      </div>
    </Page>
  )
}
