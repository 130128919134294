import { Suspense, useCallback, useMemo, useState } from 'react'
import keyBy from 'lodash/keyBy'
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'
import { MAIN_LANG } from '../consts'
import { useStateFilters } from '../hooks/filters'
import { Clip } from '../types'
import Paginator from './Paginator'
import Sorter, { SortControl } from './Sorter'
import StickyTable from './StickyTable'
import { useClips } from '../hooks/clip'

interface DocumentsPickerProps {
  initialSelectedClips: Clip[]
  onClipsChange(clips: Clip[]): void
  excludeId?: number
  multiple?: boolean
}

function ClipsPicker({
  initialSelectedClips,
  onClipsChange,
  excludeId,
  multiple = true,
}: DocumentsPickerProps) {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useStateFilters({
      page: 1,
      ordering: '',
      search: '',
      document_type: '',
      content_type: '',
    })
  const { data } = useClips(filters)
  const [selectedClips, setSelectedClips] = useState(initialSelectedClips)
  const selectedClipsById = useMemo(
    () => keyBy(selectedClips, 'id'),
    [selectedClips]
  )

  return (
    <>
      <ModalBody>
        <div
          className="d-flex flex-column"
          style={{ maxHeight: 'calc(100vh - 220px)' }}
        >
          <div className="d-flex align-items-center p-2">
            <input
              placeholder="Cerca"
              className="form-control w-auto"
              type="text"
              value={uiFilters.search}
              onChange={(e) =>
                setFiltersDebounced({ search: e.target.value, page: 1 })
              }
            />
          </div>
          <StickyTable className="mx-2 mt-2">
            <thead>
              <Sorter
                value={uiFilters.ordering}
                onSortChange={(ordering) =>
                  setFilters({
                    ordering,
                    page: 1,
                  })
                }
              >
                <tr>
                  <th></th>
                  <th>#</th>
                  <th>
                    <div className="d-flex align-items-center">
                      Titolo
                      <SortControl field={`title__${MAIN_LANG}`} />
                    </div>
                  </th>
                  <th>Thumb</th>
                </tr>
              </Sorter>
            </thead>
            <tbody>
              {data!.results.map((clip) =>
                excludeId && clip.id === excludeId ? null : (
                  <tr key={clip.id}>
                    <td>
                      <input
                        type="checkbox"
                        readOnly={!multiple && selectedClips.length > 0 && !selectedClipsById[clip.id]}
                        onChange={(e) => {
                          if (!multiple && selectedClips.length > 0 && !selectedClipsById[clip.id])
                            return
                      
                          if (e.target.checked) {
                            setSelectedClips((prev) => prev.concat(clip))
                          } else {
                            setSelectedClips((prev) =>
                              prev.filter((d) => d.id !== clip.id)
                            )
                          }
                        }}
                        checked={Boolean(selectedClipsById[clip.id])}
                      />
                    </td>
                    <td>{clip.id}</td>
                    <td>{clip.title[MAIN_LANG]}</td>
                    <td>
                      {clip.image_thumb && (
                        <img
                          style={{
                            height: 65,
                            width: 65,
                            objectFit: 'cover',
                          }}
                          alt="Thumb"
                          src={clip.image_thumb}
                          className="img-thumbnail"
                        />
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </StickyTable>
          <Paginator
            count={data!.count}
            currentPage={uiFilters.page}
            goToPage={(page) => setFilters({ page })}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          onClick={() => {
            onClipsChange(selectedClips)
          }}
          className="btn btn-success"
        >
          Conferma
        </button>
      </ModalFooter>
    </>
  )
}

interface ClipsPickerModalProps {
  isOpen: boolean
  selectedClips: Clip[]
  onClipsChange(docs: Clip[]): void
  toggle?(): void
  onClosed?: () => void
  excludeId?: number
  multiple?: boolean
}

export default function ClipPickerModal({
  isOpen,
  toggle,
  onClosed,
  selectedClips,
  multiple = true,
  onClipsChange,
  excludeId,
}: ClipsPickerModalProps) {
  const handleClipsChange = useCallback(
    (clips: Clip[]) => {
      onClipsChange(clips)
      toggle && toggle()
    },
    [onClipsChange, toggle]
  )
  
  return (
    <Modal
      fullscreen
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      scrollable={false}
      modalClassName="overflow-hidden"
    >
      <ModalHeader toggle={toggle}>Seleziona Clips</ModalHeader>
      <Suspense
        fallback={
          <div className="w-100 text-center p-5">
            <Spinner color="primary" />
          </div>
        }
      >
        <ClipsPicker
          initialSelectedClips={selectedClips}
          onClipsChange={handleClipsChange}
          multiple={multiple}
          excludeId={excludeId}
        />
      </Suspense>
    </Modal>
  )
}
