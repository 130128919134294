import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { FieldArray, useFormikContext } from 'formik'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { MAIN_LANG } from '../consts'
import { Clip } from '../types'
import classNames from 'classnames'
import { findIndex } from 'lodash'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import ClipPickerModal from './ClipPickerModal'
import { useState } from 'react'
import { Badge } from 'reactstrap'

interface ValuesWithRelatedClips {
  related_clips_data: Clip[]
}

interface DraggableClipProps {
  clip: Clip
  index: number
  onRemove(index: number): void
  setFieldValue: (field: string, value: any) => void
  values: ValuesWithRelatedClips
}
function DraggableClipItem({
  clip,
  index,
  onRemove,
  setFieldValue,
  values
}: DraggableClipProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id: clip.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      className="border-bottom row m-0 p-0 align-items-center py-2"
      ref={setNodeRef}
      style={style}
    >
      <div className="col-md-4">{clip.title[MAIN_LANG]}</div>
      <div className="col-md-2">
        <input
          type={'text'}
          value={index}
          className={'form-control'}
          onChange={(e) =>
            setFieldValue(
              'related_clips_data',
              arrayMove(values.related_clips_data, index, Number(e.target.value))
            )
          }
          name="order"
          style={{ width: 100 }}
        />
      </div>
      <div className="col-md-3">
        {clip.image_thumb && (
          <img height={50} src={clip.image_thumb} alt={clip.title[MAIN_LANG]} />
        )}
      </div>
      <div className="col-md-3 d-flex justify-content-end align-items-center">
        <span
          {...listeners}
          ref={setActivatorNodeRef}
          {...attributes}
          title="Ordina clip"
          className={classNames('sortable-handle', {
            'sortable-dragging': isDragging,
          })}
        >
          <i className="bi bi-arrows-expand"></i>
        </span>
        <button
          className="btn btn-sm px-3"
          type="button"
          title="Scollega la clip"
          onClick={() => onRemove(index)}
        >
          <i className="bi bi-unlock-fill"></i>
        </button>
      </div>
    </div>
  )
}



interface SortableRelatedClipsProps {
  excludeId?: number
}

export default function SortableRelatedClips({
  excludeId,
}: SortableRelatedClipsProps) {
  const { values, setFieldValue } = useFormikContext<ValuesWithRelatedClips>()

  const [modalPick, modalPickActions] = useModalTrigger()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (over?.id && active.id !== over.id) {
      const fromIndex = findIndex(values.related_clips_data, {
        id: +active.id,
      })
      const toIndex = findIndex(values.related_clips_data, {
        id: +over.id,
      })
      setFieldValue(
        'related_clips_data',
        arrayMove(values.related_clips_data, fromIndex, toIndex)
      )
    }
  }

  const [showClips, setShowClips] = useState(false)

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext
          items={values.related_clips_data}
          strategy={verticalListSortingStrategy}
        >
          <FieldArray
            name="related_clips_data"
            render={(arrayHelpers) => (
              <>
                <div className="col-md-12 mb-4 mt-4 d-flex align-items-center">
                  <h4>
                    Clips Collegate -{' '}
                    <Badge color="warning">
                      {values.related_clips_data.length} clip
                    </Badge>
                  </h4>
                  <button
                    className="ms-3 btn btn-sm d-flex align-items-center btn-primary"
                    type="button"
                    onClick={() => modalPickActions.open()}
                  >
                    <i className="bi bi-lock-fill me-2" /> Collega
                  </button>
                  <button
                    className="ms-3 btn btn-sm d-flex align-items-center btn-secondary"
                    type="button"
                    onClick={() => setShowClips(!showClips)}
                  >
                    <i className="bi bi-camera-video me-2" />{' '}
                    {showClips ? 'Nascondi clips' : 'Mostra clips'}
                  </button>
                </div>
                {showClips && (
                  <div className="col-md-12 mb-4">
                    <div>
                      {values.related_clips_data.map((clip, i) => (
                        <DraggableClipItem
                          index={i}
                          onRemove={arrayHelpers.remove}
                          key={clip.id}
                          setFieldValue={setFieldValue}
                          clip={clip}
                          values={values}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          />
        </SortableContext>
      </DndContext>
      <ClipPickerModal
        excludeId={excludeId}
        onClipsChange={(clips) => setFieldValue('related_clips_data', clips)}
        selectedClips={values.related_clips_data}
        isOpen={modalPick.isOpen}
        toggle={modalPickActions.toggle}
        onClosed={modalPickActions.onClosed}
      />
    </>
  )
}
