import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import MissingTranslations from '../../components/MissingTranslations'
import Page from '../../components/Page'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { MAIN_LANG } from '../../consts'
import { useQsFilters } from '../../hooks/filters'
import { useDeleteKeyword, useKeywords, useUnificaKeywords } from '../../hooks/keywords'
import { Keyword } from '../../types'
import { Field, Formik } from 'formik'
import { MultiSelectField, ReactSelectField } from '../../components/form'
import { useMemo } from 'react'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? '',
})

const MISSING_TRANSLATIONS_FIELDS = ['value']

export default function KeywordsList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data } = useKeywords(filters)
  const deleteKeyword = useDeleteKeyword()
  const [modalRm, modalRmActions] = useModalTrigger<Keyword>()
  const [modalUnifica, modalUnificaActions] = useModalTrigger()

  const unificaKeywords = useUnificaKeywords()

  const keywordsOptions = useMemo(
    () =>
      data?.map((keyword) => ({
        value: keyword.id,
        label: keyword.value.it,
      })) ?? [],
    [data]
  )

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Keywords</h2>
        </div>
        <div className="d-flex align-items-center">
          <Button
            color='primary'
            className='me-2'
            outline
            onClick={modalUnificaActions.toggle}
          >
            Unifica
          </Button>
          <Link className="btn btn-primary" to="/keywords/new">
            <i className="bi bi-plus-circle me-2"></i> Aggiungi
          </Link>
        </div>
      </Header>
      <div className="d-flex justify-content-between mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
      </div>
      <StickyTable className="flex-1">
        <thead>
          <Sorter
            value={uiFilters.ordering}
            onSortChange={(ordering) =>
              setFilters({
                ordering,
                page: 1,
              })
            }
          >
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Keyword (it)
                  <SortControl field={`value__${MAIN_LANG}`} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Keyword (en)
                  <SortControl field={`value__en`} />
                </div>
              </th>
            </tr>
          </Sorter>
        </thead>
        <tbody>
          {data!.map((keyword) => (
            <tr key={keyword.id}>
              <td>{keyword.id}</td>
              <td>
                <Link to={`/keywords/${keyword.id}`}>{keyword.value.it}</Link>
                <MissingTranslations
                  data={keyword}
                  fields={MISSING_TRANSLATIONS_FIELDS}
                />
              </td>
              <td>
                {keyword.value.en}
                <MissingTranslations
                  data={keyword}
                  fields={MISSING_TRANSLATIONS_FIELDS}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare la keyword {modalRm.value.value.it}{' '}
              ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteKeyword.isLoading}
            onClick={async () => {
              await deleteKeyword.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        isOpen={modalUnifica.isOpen}
        toggle={modalUnificaActions.toggle}
      >
        <ModalHeader toggle={modalUnificaActions.toggle}>Unifica</ModalHeader>
        <Formik
          initialValues={{
            keyword_to_keep: null,
            keywords_to_delete: [],
          }}
          onSubmit={async (values) => {
            if (
              !values.keyword_to_keep ||
              values.keywords_to_delete.length === 0
            )
              return
            await unificaKeywords.mutateAsync({
              keyword_to_keep: values.keyword_to_keep,
              keywords_to_delete: values.keywords_to_delete,
            }).then(() => {
              modalUnificaActions.toggle()
            }
            )
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div>
                  <Field
                    component={ReactSelectField}
                    name="keyword_to_keep"
                    label="Keyword da mantenere"
                    placeholder="Seleziona la keyword da mantenere"
                    options={keywordsOptions}
                  />
                </div>
                <div>
                  <Field
                    component={MultiSelectField}
                    name="keywords_to_delete"
                    placeholder="Seleziona le keyword da eliminare"
                    label="Keyword da eliminare"
                    options={keywordsOptions.filter(
                      (option) => option.value !== values.keyword_to_keep
                    )}
                    isMulti
                  />
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <button
                  onClick={modalUnificaActions.toggle}
                  className="btn btn-outline-primary"
                >
                  Annulla
                </button>
                <button className="btn btn-success" type="submit">
                  <i className="bi bi-save-fill me-1"></i>
                  Salva
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    </Page>
  )
}
