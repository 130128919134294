import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import { BaseTerritorioDetail, Territorio } from '../types'
import Footer from './Footer'
import { InputField, TextareaField, transformErrorForForm } from './form'
import SortableRelatedClips from './SortableRelatedClips'

const TerritorioSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),
  description: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Descrizione')]))
  ),

  extra_text: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [l, Yup.string().label('Testo aggiuntivo')])
    )
  ),
})

interface Props {
  territorio?: Territorio
  save: (territorio: Territorio) => Promise<unknown>
}

export const initialThemeValues: BaseTerritorioDetail = {
  title: EMPTY_LANG_FIELD,
  description: EMPTY_LANG_FIELD,
  extra_text: EMPTY_LANG_FIELD,
  credits: EMPTY_LANG_FIELD,
  related_clips_data: [],
  order: 0,
}

export default function TerritorioForm({ territorio, save }: Props) {
  const [lang, setLang] = useState('en')

  return (
    <Formik
      enableReinitialize
      onSubmit={(territorio, { setErrors }) =>
        save(territorio).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={TerritorioSchema}
      initialValues={(territorio ?? initialThemeValues) as Territorio}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div>
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Titolo [it]`}
                name={`title.it`}
                component={InputField}
              />
              <Field
                label={`Descrizione [it]`}
                name={`description.it`}
                rows={5}
                component={TextareaField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Titolo [${lang}]`}
                name={`title.${lang}`}
                component={InputField}
              />
              <Field
                label={`Descrizione [${lang}]`}
                name={`description.${lang}`}
                rows={5}
                component={TextareaField}
              />
            </div>
            <div className="col-md-12">
              <Field label="Ordine" name="order" component={InputField} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4 mt-4">
              <h4>Informazioni aggiuntive</h4>
            </div>
            <div className="col-md-6">
              <Field
                label={`Testo aggiuntivo [it]`}
                component={TextareaField}
                rows={5}
                name="extra_text.it"
              />
            </div>

            <div className="col-md-6">
              <Field
                label={`Testo aggiuntivo [${lang}]`}
                name={`extra_text.${lang}`}
                rows={5}
                component={TextareaField}
              />
            </div>
            <div className="col-md-6 mt-4">
              <Field
                label="Credits"
                name="credits.it"
                component={TextareaField}
                rows={5}
              />
            </div>
            <div className="col-md-6 mt-4">
              <Field
                label={`Credits [${lang}]`}
                name={`credits.${lang}`}
                component={TextareaField}
                rows={5}
              />
            </div>
          </div>
          <div>
            <SortableRelatedClips />
          </div>
          <Footer>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </Footer>
        </form>
      )}
    </Formik>
  )
}
