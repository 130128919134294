import { Field, Formik } from 'formik'
import { MouseEventHandler } from 'react'
import * as Yup from 'yup'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from 'reactstrap'
import { FileField, transformErrorForForm } from './form'

const INITIAL_VALUES: {
  file: File | null
} = {
  file: null,
}

const ClipsImportSchema = Yup.object().shape({
  file: Yup.mixed().required().label('File'),
})

interface ModalImportClipsInnerProps {
  importClips: (file: File) => Promise<any>
  toggle?: (x: any) => void
}

export function ModalImportClipsInner({
  importClips,
  toggle,
}: ModalImportClipsInnerProps) {
  return (
    <Formik
      validateOnMount
      initialValues={INITIAL_VALUES}
      validationSchema={ClipsImportSchema}
      onSubmit={(values, { setErrors, setFieldValue }) =>
        importClips(values.file!).then(
          () => {
            toggle?.(undefined)
          },
          (err) => {
            // NOTE: This avoid net::ERR_UPLOAD_FILE_CHANGED on Chrome lol
            setFieldValue('file', '', false)
            setErrors(transformErrorForForm(err, ['import']))
          }
        )
      }
    >
      {({ isSubmitting, isValid, handleSubmit, errors }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Field
              id="import-docs-file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              component={FileField}
              name="file"
            />
            {(errors as any).__noFieldsServerError && (
              <div className="alert alert-danger mt-2">
                <b className="m-0 p-0">
                  Errore del server durante l'importazione.
                </b>
              </div>
            )}
            {(errors as any).import && (
              <div className="alert alert-danger mt-2">
                <b className="m-0 p-0">Errori durante l'importazione:</b>
                <ul className="mt-2">
                  {(errors as any).import.map(
                    (importErr: string, i: number) => (
                      <li key={i}>{importErr}</li>
                    )
                  )}
                </ul>
              </div>
            )}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <button
              type="button"
              onClick={toggle}
              disabled={isSubmitting}
              className="btn btn-outline-primary"
            >
              Annulla
            </button>
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="bi bi-file-earmark-arrow-up-fill"></i> Importa
            </button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  )
}

interface ModalImportClipsProps extends ModalProps {
  importClips: (file: File) => Promise<any>
}

export default function ModalImportClips({
    importClips,
  ...props
}: ModalImportClipsProps) {
  return (
    <Modal centered size="lg" {...props}>
      <ModalHeader toggle={props.toggle as MouseEventHandler}>
        Importa Clips
      </ModalHeader>
      <ModalImportClipsInner importClips={importClips} toggle={props.toggle} />
    </Modal>
  )
}
