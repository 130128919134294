import classNames from 'classnames'
import { FastField, Field, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { EMPTY_LANG_FIELD, LANGS, LANGS_WITHOUT_DEFAULT } from '../consts'
import { Author, BaseAuthor } from '../types'
import {
  DateField,
  InputField,
  TextareaField,
  transformErrorForForm,
} from './form'

const initialValues: BaseAuthor = {
  biography: EMPTY_LANG_FIELD,
  name: '',
  birth_date: null,
  death_date: null,
}

const AuthorSchema = Yup.object().shape({
  name: Yup.string().required().label('Nome'),
  biography: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Biografia')]))
  ),
})

interface Props {
  author?: Author
  save: (author: Author) => Promise<unknown>
}

export default function AuthorForm({ save, author }: Props) {
  const [lang, setLang] = useState('en')
  return (
    <Formik
      validationSchema={AuthorSchema}
      onSubmit={(author, { setErrors }) =>
        save({
          ...author,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(author ?? initialValues) as Author}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div className="d-flex">
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-4">
              <Field label="Nome" name="name" component={InputField} />
            </div>
            <div className="col-md-4">
              <Field
                name="birth_date"
                label={'Data di nascita'}
                component={DateField}
              />
            </div>
            <div className="col-md-4">
              <Field
                name="death_date"
                label={'Data di morte'}
                component={DateField}
              />
            </div>
            <div className="col-md-6">
              <FastField
                label={`Biografia [it]`}
                name={`biography.it`}
                component={TextareaField}
              />
            </div>
            <div className="col-md-6">
              <FastField
                label={`Biografia [${lang}]`}
                name={`biography.${lang}`}
                component={TextareaField}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
