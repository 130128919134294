import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import TerritoryCreate from './TerritoryCreate'
import TerritoryEdit from './TerritoryEdit'
import TerritoriesList from './TerritoriesList'

export default function Territories() {
  return (
    <Routes>
      <Route index element={<TerritoriesList />} />
      <Route path="new" element={<TerritoryCreate />} />
      <Route path=":id" element={<TerritoryEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
