import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import ExtraCreate from './ExtraCreate'
import ExtraEdit from './ExtraEdit'
import ExtraList from './ExtrasList'

export default function Extras() {
  return (
    <Routes>
      <Route index element={<ExtraList />} />
      <Route path="new" element={<ExtraCreate />} />
      <Route path=":id" element={<ExtraEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
