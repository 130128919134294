import { Link, useNavigate, useParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import ClipForm from '../../components/ClipForm'
import { MAIN_LANG } from '../../consts'
import { Clip } from '../../types'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Page from '../../components/Page'
import Header from '../../components/Header'
import { useClip, useDeleteClip, useUpdateClip } from '../../hooks/clip'

export default function ClipEdit() {
  const { id } = useParams()
  const { data: clip } = useClip(+id!)
  const updateClip = useUpdateClip()
  const deleteClip = useDeleteClip()
  const [modalRm, modalRmActions] = useModalTrigger<Clip>()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/clips">Clips</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {clip!.title[MAIN_LANG]}
            </li>
          </ol>
        </nav>
        <button
          className="btn btn-danger"
          onClick={() => modalRmActions.open(clip)}
        >
          <i className="bi bi-trash3-fill"></i> Elimina
        </button>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <ClipForm
          clip={clip}
          save={(formClip) =>
            updateClip.mutateAsync(formClip).then(() =>
              toast.success('Clip salvata con successo!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              })
            )
          }
        />
      </div>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare la clip{' '}
              {modalRm.value.title[MAIN_LANG]} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-danger"
            disabled={deleteClip.isLoading}
            onClick={async () => {
              await deleteClip.mutateAsync(modalRm.value!.id)
              navigate('/clips')
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
