import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import SlideCreate from './SlideCreate'
import SlideEdit from './SlideEdit'
import SlidesList from './SlidesList'

export default function SlidesHome() {
  return (
    <Routes>
      <Route index element={<SlidesList />} />
      <Route path="new" element={<SlideCreate />} />
      <Route path=":id" element={<SlideEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
