import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Keyword } from '../types'

export function useKeywords(params: Record<string, any> = {}) {
  return useQuery<Keyword[]>(
    ['keywords', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/keywords/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useKeyword(id: number) {
  return useQuery<Keyword>(
    ['keywords', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/keywords/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteKeyword() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/keywords/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['keywords'])
        client.invalidateQueries(['keyword'])
      },
    }
  )
}

export function useCreateKeyword() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (content: Keyword) =>
        axios
          .post(`/api/admin/keywords/`, content, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Keyword)
    ),
    {
      onSuccess(savedKeyword) {
        client.invalidateQueries(['kewyords'])
        client.setQueryData(['keywords', savedKeyword.id], savedKeyword)
      },
    }
  )
}

export function useUpdateKeyword() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (keyword: Keyword) =>
        axios
          .put(`/api/admin/keywords/${keyword.id}/`, keyword, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Keyword)
    ),
    {
      onSuccess(savedKeyword) {
        client.invalidateQueries(['keywords'])
        client.setQueryData(['keyword', savedKeyword.id], savedKeyword)
      },
    }
  )
}

export function useUnificaKeywords() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({
          keyword_to_keep,
          keywords_to_delete,
        }: {
          keyword_to_keep: number
          keywords_to_delete: number[]
        }) =>
          axios
            .post(
              `/api/admin/keywords/unifica/`,
              { keyword_to_keep, keywords_to_delete },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Keyword[])
    ),
    {
      onSuccess() {
        client.invalidateQueries(['keywords'])
      },
    }
  )
}
