import classNames from 'classnames'
import { Field, FieldArray, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import { BaseExtra, Clip, Extra, ExtraImage } from '../types'
import {
  CheckBoxField,
  DateField,
  FileField,
  InputField,
  RichTextField,
  SelectField,
  TextareaField,
  transformErrorForForm,
} from './form'
import SortableRelatedClips from './SortableRelatedClips'
import { Button } from 'reactstrap'
import {
  useCreateExtraImage,
  useDeleteExtraImage,
  useUpdateExtra,
  useUpdateExtraImage,
} from '../hooks/extra'
import ClipPickerModal from './ClipPickerModal'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'

const SerieSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),

  description_small: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Testo lista'
        ),
      ])
    )
  ),

  type: Yup.string().required().label('Tipo'),

  cover_image: Yup.mixed().nullable().label('Immagine di copertina'),
})

interface Props {
  extra?: Extra
  save: (extra: Extra) => Promise<unknown>
}

export const initialThemeValues: BaseExtra = {
  title: EMPTY_LANG_FIELD,
  cover_image: null,
  date_from: null,
  date_to: null,
  hour: null,
  place: '',
  blocks_content: [],
  description_small: EMPTY_LANG_FIELD,
  type: null,
  draft: true,
  // related_clips_data: [],
}

export default function ExtraForm({ extra, save }: Props) {
  const [lang, setLang] = useState('en')
  const [isOpenBlock, setIsOpenBlock] = useState<number | null>(null)

  const createExtraImage = useCreateExtraImage()
  const updateExtraImage = useUpdateExtraImage()
  const deleteExtraImage = useDeleteExtraImage()

  const updateExtra = useUpdateExtra()
  const [modalPick, modalPickActions] = useModalTrigger()
  const [modalPickSingle, modalPickSingleActions] = useModalTrigger()

  const [blockAdd, setBlockAdd] = useState<string>('')

  return (
    <Formik
      enableReinitialize
      onSubmit={(extra, { setErrors }) => {
        save(extra).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }}
      validationSchema={SerieSchema}
      initialValues={(extra ?? initialThemeValues) as Extra}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div>
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Titolo [it]`}
                name={`title.it`}
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Titolo [${lang}]`}
                name={`title.${lang}`}
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Testo lista [it]`}
                name={`description_small.it`}
                component={TextareaField}
                rows={3}
              />
            </div>

            <div className="col-md-6">
              <Field
                label={`Testo lista [${lang}]`}
                name={`description_small.${lang}`}
                component={TextareaField}
                rows={3}
              />
            </div>
            <div className="col-md-6">
              <Field label={`Tipo`} name={`type`} component={SelectField} required>
                <option value={''}></option>
                <option value={'evento'}>Evento</option>
                <option value={'approfondimento'}>Approfondimento</option>
                <option value={'contenuto_speciale'}>Contenuto speciale</option>
              </Field>
            </div>
            <div className="col-md-6">
              <Field
                label="Immagine di copertina"
                component={FileField}
                fileType="image"
                name="cover_image"
              />
            </div>
            {(values.type === 'approfondimento' ||
              values.type === 'contenuto_speciale') && (
              <>
                <div className="col-md-6">
                  <Field
                    name="date_from"
                    label={'Data'}
                    component={DateField}
                  />
                </div>
              </>
            )}
            <div className="col-md-6">
              <Field
                label="Bozza"
                name="draft"
                component={CheckBoxField}
                type="checkbox"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4 mt-4">
              <h4>Informazioni aggiuntive</h4>
            </div>

            {values.type === 'evento' && (
              <>
                <div className="col-md-6">
                  <Field
                    name="date_from"
                    label={'Data da'}
                    component={DateField}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="date_to"
                    label={'Data a'}
                    component={DateField}
                  />
                </div>
                <div className="col-md-6">
                  <Field name="hour" label={'Ora'} component={InputField} />
                </div>
                <div className="col-md-6">
                  <Field name="place" label={'Luogo'} component={InputField} />
                </div>
              </>
            )}
            {!values.id && (
              <div className="col-md-12">
                <div className="alert alert-warning">
                  <i className="bi bi-exclamation-triangle-fill me-2"></i>
                  Salva le informazioni di base per poter aggiungere i contenuti
                  della pagina
                </div>
              </div>
            )}
            {values.id && (
              <>
                <div className="col-md-12">
                  <h5>Contenuti pagina</h5>
                </div>
                <div className="col-md-12">
                  <FieldArray name="blocks_content">
                    {({ push, remove }) => (
                      <>
                        <div className="col-md-12 d-flex align-items-center">
                          <select
                            className="form-select me-2"
                            style={{ width: '400px' }}
                            onChange={(e) => {
                              setBlockAdd(e.target.value)
                            }}
                          >
                            <option value="">Seleziona tipo di blocco</option>
                            <option value="text">Testo</option>
                            <option value="image">Immagine</option>
                            <option value="video">Video</option>
                            <option value="clips_list">Lista di clip</option>
                            <option value="clip_from_archive">
                              Clip da archivio
                            </option>
                          </select>
                          <Button
                            disabled={!blockAdd}
                            onClick={async () => {
                              if (!blockAdd) {
                                return
                              }
                              const block =
                                blockAdd === 'text'
                                  ? {
                                      type: 'text',
                                      text: { it: '', en: '' },
                                    }
                                  : blockAdd === 'image'
                                  ? {
                                      type: 'image',
                                      image: '',
                                    }
                                  : blockAdd === 'video'
                                  ? {
                                      type: 'video',
                                      video: '',
                                    }
                                  : blockAdd === 'clips_list'
                                  ? {
                                      type: 'clips_list',
                                      clips: [],
                                    }
                                  : {
                                      type: 'clip_from_archive',
                                      clip: null,
                                    }

                              await updateExtra.mutateAsync({
                                ...values,
                                blocks_content: [
                                  ...values.blocks_content,
                                  block,
                                ],
                              })
                            }}
                            color="primary"
                          >
                            Aggiungi blocco
                          </Button>
                        </div>
                        {values.blocks_content.map((block, index) => (
                          <div className="card bg-light mt-3" key={index}>
                            <div
                              className="card-header d-flex justify-content-between align-items-center cursor-pointer"
                              onClick={() =>
                                setIsOpenBlock(
                                  isOpenBlock === index ? null : index
                                )
                              }
                            >
                              <div className="card-title">
                                <h5 className="mb-0">
                                  Blocco {index + 1} -
                                  {block.type === 'text' && ' Testo'}
                                  {block.type === 'image' && ' Immagine'}
                                  {block.type === 'video' && ' Video Embed'}
                                  {block.type === 'clips_list' &&
                                    ' Lista di clip'}
                                  {block.type === 'clip_from_archive' &&
                                    ' Clip da archivio'}
                                </h5>
                              </div>
                              <div className="d-flex">
                                <Button
                                  onClick={async () => {
                                    setIsOpenBlock(
                                      isOpenBlock === index ? null : index
                                    )
                                    await updateExtra.mutateAsync({
                                      ...values,
                                    })
                                  }}
                                  color="success"
                                  size="sm"
                                  className="me-2"
                                >
                                  <i className="bi bi-pencil"></i>
                                </Button>

                                {/* button to move up */}
                                {/* button to move down */}

                                <Button
                                  color="primary"
                                  className="me-2"
                                  size="sm"
                                  disabled={index === 0}
                                  onClick={async (e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    const blocks = values.blocks_content
                                    const block = blocks[index]
                                    blocks.splice(index, 1)
                                    blocks.splice(index - 1, 0, block)
                                    setFieldValue('blocks_content', blocks)
                                    await updateExtra.mutateAsync({
                                      ...values,
                                      blocks_content: blocks,
                                    })
                                  }}
                                >
                                  <i className="bi bi-arrow-up"></i>
                                </Button>
                                <Button
                                  color="primary"
                                  size="sm"
                                  disabled={
                                    index === values.blocks_content.length - 1
                                  }
                                  onClick={async (e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    const blocks = values.blocks_content
                                    const block = blocks[index]
                                    blocks.splice(index, 1)
                                    blocks.splice(index + 1, 0, block)
                                    setFieldValue('blocks_content', blocks)
                                    await updateExtra.mutateAsync({
                                      ...values,
                                      blocks_content: blocks,
                                    })
                                  }}
                                >
                                  <i className="bi bi-arrow-down"></i>
                                </Button>
                                <Button
                                  onClick={async (e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (block.image_pk) {
                                      await deleteExtraImage
                                        .mutateAsync(block.image_pk)
                                        .then(() => {
                                          remove(index)
                                        })
                                    }
                                    await updateExtra.mutateAsync({
                                      ...values,
                                      blocks_content:
                                        values.blocks_content.filter(
                                          (_, i) => i !== index
                                        ),
                                    })
                                  }}
                                  color="danger"
                                  size="sm"
                                  className="ms-2"
                                >
                                  <i className="bi bi-trash"></i>
                                </Button>
                              </div>
                            </div>
                            {isOpenBlock === index && (
                              <div className="card-body bg-white" key={index}>
                                <Field
                                  name={`blocks_content.${index}.type`}
                                  label={'Tipo'}
                                  component={SelectField}
                                >
                                  <option value="text">Testo</option>
                                  <option value="image">Immagine</option>
                                  <option value="video">Video</option>
                                  <option value="clips_list">
                                    Lista di clip
                                  </option>
                                  <option value="clip_from_archive">
                                    Clip da archivio
                                  </option>
                                </Field>
                                {block.type === 'text' && (
                                  <>
                                    <Field
                                      name={`blocks_content.${index}.text.it`}
                                      label={'Testo'}
                                      component={RichTextField}
                                      rows={3}
                                    />
                                    <Field
                                      name={`blocks_content.${index}.text.en`}
                                      label={'Testo [en]'}
                                      component={RichTextField}
                                      rows={3}
                                    />
                                  </>
                                )}
                                {block.type === 'image' && (
                                  <>
                                    <input
                                      type="file"
                                      onChange={async (
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (!e.target.files) {
                                          return
                                        }
                                        if (block.image_pk) {
                                          await updateExtraImage
                                            .mutateAsync({
                                              image: e.target.files![0],
                                              extra: values.id,
                                              id: block.image_pk,
                                            })
                                            .then((res: ExtraImage) => {
                                              setFieldValue(
                                                `blocks_content.${index}.image`,
                                                res.image
                                              )
                                            })
                                        } else {
                                          await createExtraImage
                                            .mutateAsync({
                                              image: e.target.files![0],
                                              extra: values.id,
                                            })
                                            .then((res: ExtraImage) => {
                                              console.log(res)
                                              setFieldValue(
                                                `blocks_content.${index}.image`,
                                                res.image
                                              )
                                              setFieldValue(
                                                `blocks_content.${index}.image_pk`,
                                                res.id
                                              )
                                            })
                                        }
                                      }}
                                    />
                                    <div className="d-flex justify-content-center">
                                      <img
                                        src={block.image}
                                        alt=""
                                        height={300}
                                      />
                                    </div>
                                  </>
                                )}
                                {block.type === 'video' && (
                                  <>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `blocks_content.${index}.video`,
                                          e.target.value
                                        )
                                      }}
                                      value={block.video}
                                      className="form-control"
                                      placeholder="Inserisci il codice di condivisione del video vimeo"
                                    />
                                    {block.video && (
                                      <div className="d-flex justify-content-center mt-3">
                                        <iframe
                                          src={`https://player.vimeo.com/video/${
                                            block.video.split('/')[
                                              block.video.split('/').length - 1
                                            ]
                                          }`}
                                          width="640"
                                          height="360"
                                          frameBorder="0"
                                          allow="autoplay; fullscreen; picture-in-picture"
                                          allowFullScreen
                                        ></iframe>
                                      </div>
                                    )}
                                  </>
                                )}
                                {block.type === 'clips_list' && (
                                  <div>
                                    <Button
                                      onClick={modalPickActions.toggle}
                                      color="primary"
                                      className="mb-3"
                                    >
                                      Seleziona clip
                                    </Button>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <h5>Clip selezionate</h5>
                                      </div>
                                      {block?.clips?.map((clip: Clip) => (
                                        <div key={clip.id} className="col-md-3">
                                          <div>
                                            <div className="me-3">
                                              <img
                                                src={clip.image_thumb ?? ''}
                                                alt={clip.title[MAIN_LANG]}
                                                className="img-fluid"
                                              />
                                            </div>
                                            <div>
                                              <div>{clip.title[MAIN_LANG]}</div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {block.type === 'clip_from_archive' && (
                                  <div>
                                    <Button
                                      onClick={modalPickSingleActions.toggle}
                                      color="primary"
                                      className="mb-3"
                                    >
                                      Seleziona clip
                                    </Button>
                                    {block?.clip && (
                                      <div className="row">
                                        <div className="col-md-12">
                                          <h5>Clip selezionata</h5>
                                        </div>
                                        <div className="offset-md-4 col-md-6">
                                          <div>
                                            <div className="me-3">
                                              <img
                                                src={
                                                  block.clip.image_thumb ?? ''
                                                }
                                                alt={
                                                  block.clip.title[MAIN_LANG]
                                                }
                                                className="img-fluid"
                                              />
                                            </div>
                                            <div>
                                              <div>
                                                {block.clip.title[MAIN_LANG]}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            <ClipPickerModal
                              excludeId={
                                block?.clips?.map((c: Clip) => c.id) ?? []
                              }
                              onClipsChange={(clips) =>
                                setFieldValue(
                                  `blocks_content.${index}.clips`,
                                  clips
                                )
                              }
                              selectedClips={block?.clips ?? []}
                              isOpen={modalPick.isOpen}
                              toggle={modalPickActions.toggle}
                              onClosed={modalPickActions.onClosed}
                            />
                            <ClipPickerModal
                              onClipsChange={(clips: Clip[]) =>
                                setFieldValue(
                                  `blocks_content.${index}.clip`,
                                  clips[0] as Clip
                                )
                              }
                              multiple={false}
                              selectedClips={block?.clip ? [block.clip] : []}
                              isOpen={modalPickSingle.isOpen}
                              toggle={modalPickSingleActions.toggle}
                              onClosed={modalPickSingleActions.onClosed}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </div>
              </>
            )}
          </div>
          <div>{/* <SortableRelatedClips /> */}</div>
          <div className="d-flex justify-content-end mt-4">
            <button
              style={{
                position: 'fixed',
                bottom: 20,
                right: 40,
              }}
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
