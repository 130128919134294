import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import MissingTranslations from '../../components/MissingTranslations'
import ModalImportClips from '../../components/ModalImportClips'
import Page from '../../components/Page'
import Paginator from '../../components/Paginator'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useAuthorsForSelect } from '../../hooks/authors'
import { useClips, useDeleteClip, useImportClips } from '../../hooks/clip'
import { useQsFilters } from '../../hooks/filters'
import { Clip } from '../../types'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  author: params.get('author') ?? '',
  ordering: params.get('ordering') ?? '',
})

const MISSING_TRANSLATIONS_FIELDS = ['description']

export default function ClipsList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data } = useClips(filters)
  const { data: authors } = useAuthorsForSelect()
  const deleteClip = useDeleteClip()
  const [modalRm, modalRmActions] = useModalTrigger<Clip>()
  const [modalImport, modalImportActions] = useModalTrigger()
  const importClips = useImportClips()

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Clips</h2>
          <div className="counter ms-2 mb-1">
            <small>
              {data!.count}
              {' / '}
              {data!.total_count}
            </small>
          </div>
        </div>
        <div>
          <button
            className="btn btn-primary me-2"
            onClick={() => {
              modalImportActions.open()
            }}
          >
            <i className="bi bi-file-earmark-arrow-up-fill me-2" />
            Importa
          </button>
          <Link className="btn btn-primary" to="/clips/new">
            <i className="bi bi-plus-circle me-2"></i> Aggiungi
          </Link>
        </div>
      </Header>
      <div className="d-flex mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
        <select
          onChange={(e) => setFilters({ author: e.target.value, page: 1 })}
          value={uiFilters.author}
          className="form-select w-auto ms-3"
          placeholder="Filtra per autore"
        >
          <option value={''}>Filtra per autore</option>
          {authors?.map((author) => (
            <option key={author.id} value={author.id}>
              {author.name}
            </option>
          ))}
        </select>
      </div>
      <StickyTable className="flex-1">
        <thead>
          <Sorter
            value={uiFilters.ordering}
            onSortChange={(ordering) =>
              setFilters({
                ordering,
                page: 1,
              })
            }
          >
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Titolo
                  <SortControl field="title__it" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Autore
                  <SortControl field="author__name" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Archivio
                  <SortControl field="archive" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Formato
                  <SortControl field="format" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">Preview</div>
              </th>
              <th></th>
            </tr>
          </Sorter>
        </thead>
        <tbody>
          {data!.results.map((clip) => (
            <tr key={clip.id}>
              <td>{clip.id}</td>
              <td>
                <Link to={`/clips/${clip.id}`}>{clip.title.it}</Link>
                <MissingTranslations
                  data={clip}
                  fields={MISSING_TRANSLATIONS_FIELDS}
                />
              </td>
              <td>{clip.author_data?.name}</td>
              <td>{clip.archive}</td>
              <td>{clip.format}</td>
              <td>
                {clip.image_thumb && (
                  <img src={clip.image_thumb} alt={clip.title.it} height={50} />
                )}
              </td>
              <td>
                <i
                  onClick={() => modalRmActions.open(clip)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare la clip {modalRm.value.title.it} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteClip.isLoading}
            onClick={async () => {
              await deleteClip.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <div className="position-relative">
        <Paginator
          count={data!.count}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
      <ModalImportClips
        importClips={async (file) => {
          const importedData = await importClips.mutateAsync(file)
          toast.success(
            `${importedData.imported_clips} clips importate con successo!`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
            }
          )
        }}
        isOpen={modalImport.isOpen}
        toggle={modalImportActions.toggle}
        onClosed={modalImportActions.onClosed}
      />
    </Page>
  )
}
