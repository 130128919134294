import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import SerieCreate from './SerieCreate'
import SerieEdit from './SerieEdit'
import SeriesList from './SeriesList'

export default function Series() {
  return (
    <Routes>
      <Route index element={<SeriesList />} />
      <Route path="new" element={<SerieCreate />} />
      <Route path=":id" element={<SerieEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
