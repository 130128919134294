import { Link, useNavigate } from 'react-router-dom'
import AuthorForm from '../../components/AuthorForm'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateAuthor } from '../../hooks/authors'

export default function AuthorCreate() {
  const createAuthor = useCreateAuthor()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/authors">Autori</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <AuthorForm
          save={async (author) => {
            const savedAuthor = await createAuthor.mutateAsync(author)
            navigate(`/authors/${savedAuthor.id}`)
          }}
        />
      </div>
    </Page>
  )
}
