import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useAuthUser } from 'use-eazy-auth'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Paginator from '../../components/Paginator'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useAuthors, useDeleteAuthor } from '../../hooks/authors'
import { useQsFilters } from '../../hooks/filters'
import { Author, User } from '../../types'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? '',
})

export default function AuthorsList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data } = useAuthors(filters)
  const { user } = useAuthUser<User>()
  const deleteAuthor = useDeleteAuthor()
  const [modalRm, modalRmActions] = useModalTrigger<Author>()

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Autori</h2>
        </div>
        {user!.is_superuser && (
          <Link className="btn btn-primary" to="/authors/new">
            <i className="bi bi-plus-circle me-2"></i> Aggiungi
          </Link>
        )}
      </Header>
      <div className="d-flex justify-content-between mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
      </div>
      <StickyTable className="flex-1">
        <thead>
          <Sorter
            value={uiFilters.ordering}
            onSortChange={(ordering) =>
              setFilters({
                ordering,
                page: 1,
              })
            }
          >
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Nome
                  <SortControl field="name" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Data di nascita <SortControl field="birth_date" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Data di morte <SortControl field="death_date" />
                </div>
              </th>
              <th></th>
            </tr>
          </Sorter>
        </thead>
        <tbody>
          {data!.results.map((author) => (
            <tr key={author.id}>
              <td>{author.id}</td>
              <td>
                <Link to={`/authors/${author.id}`}>{author.name}</Link>
              </td>
              <td>{author.birth_date}</td>
              <td>{author.death_date}</td>
              <td>
                <i
                  onClick={() => modalRmActions.open(author)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare l'autore {modalRm.value.name} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteAuthor.isLoading}
            onClick={async () => {
              await deleteAuthor.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <div className="position-relative">
        <Paginator
          count={data!.count}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </Page>
  )
}
