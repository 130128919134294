import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Clip, PaginatedDJResponse } from '../types'
import { makeFormDataEncoder } from './utils'

export function useClips(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Clip>>(
    ['clips', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/clips/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useFormats(params: Record<string, any> = {}) {
  return useQuery<string[]>(
    ['formats', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/clips/formats?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}



export function useClip(id: number) {
  return useQuery<Clip>(
    ['clip', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/clips/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteClip() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/clips/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['clips'])
        client.invalidateQueries(['clip'])
      },
    }
  )
}

const makeDocFormData = makeFormDataEncoder({
  fileFields: [],
  excludeFields: [
    'image_medium',
    'image_preview',
    'image_thumb',
  ],
})

export function useCreateClip() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (clip: Clip) =>
        axios
          .post(`/api/admin/clips/`, makeDocFormData(clip), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Clip)
    ),
    {
      onSuccess(savedClip) {
        client.invalidateQueries(['clips'])
        client.setQueryData(['clip', savedClip.id], savedClip)
      },
    }
  )
}

export function useUpdateClip() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (clip: Clip) =>
        axios
          .put(`/api/admin/clips/${clip.id}/`, makeDocFormData(clip), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Clip)
    ),
    {
      onSuccess(savedClip) {
        client.invalidateQueries(['clips'])
        client.setQueryData(['clip', savedClip.id], savedClip)
      },
    }
  )
}

export interface ImportedClipData {
  imported_clips: number
}

export function useImportClips() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise((token: string) => (file: File) => {
      const fd = new FormData()
      fd.append('file', file)
      return axios
        .put('/api/admin/clips/import/', fd, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => r.data as ImportedClipData)
    }),
    {
      onSuccess() {
        client.invalidateQueries(['clips'])
        client.invalidateQueries(['clip'])
      },
    }
  )
}
