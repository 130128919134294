import { Route, Routes } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import NotFound from '../../components/NotFound'
import { User } from '../../types'
import ClipCreate from './ClipCreate'
import ClipEdit from './ClipEdit'
import ClipsList from './ClipsList'

export default function Clips() {
  const { user } = useAuthUser<User>()
  return (
    <Routes>
      <Route index element={<ClipsList />} />
      {user!.is_superuser && <Route path="new" element={<ClipCreate />} />}
      <Route path=":id" element={<ClipEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
