import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BlockHome, Slide } from '../types'

export function useBlocksHome(params: Record<string, any> = {}) {
  return useQuery<BlockHome[]>(
    ['blocks-home', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/blocks-home/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useBlockHome(id: number) {
  return useQuery<BlockHome>(
    ['block-home', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/blocks-home/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteBlockHome() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/blocks-home/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['blocks-home'])
        client.invalidateQueries(['block-home'])
      },
    }
  )
}

export function useCreateBlockHome() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (block: BlockHome) =>
        axios
          .post(`/api/admin/blocks-home/`, block, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as BlockHome)
    ),
    {
      onSuccess(savedBlock) {
        client.invalidateQueries(['blocks-home'])
        client.setQueryData(['block-home', savedBlock.id], savedBlock)
      },
    }
  )
}

export function useUpdateBlockHome() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (block: BlockHome) =>
        axios
          .put(`/api/admin/blocks-home/${block.id}/`, block, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as BlockHome)
    ),
    {
      onSuccess(savedBlock) {
        client.invalidateQueries(['blocks-home'])
        client.setQueryData(['block-home', savedBlock.id], savedBlock)
      },
    }
  )
}
