import { FieldProps, getIn } from 'formik'
import CreatableSelect from 'react-select/creatable'
import dateLocaleIT from 'date-fns/locale/it'
import { format } from 'date-fns'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import uniq from 'lodash/uniq'
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { OnChangeValue } from 'react-select'
import Select from 'react-select'
import { MapContainer, Marker, useMap } from 'react-leaflet'
import { TileLayer } from 'react-leaflet'
import { LatLngTuple } from 'leaflet'

registerLocale('it', dateLocaleIT)

type InputFieldProps = FieldProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  }

export const InputField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = 'mb-2',
  required = false,
  onChange,
  ...props
}: InputFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <input
        {...field}
        {...props}
        className={`form-control ${error && touch ? 'is-invalid' : ''}`}
        onChange={(e) => {
          field.onChange(e)
          onChange && onChange(e)
        }}
        value={field.value ?? ''}
      />
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

type TextareaFieldProps = FieldProps &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  }

export const TextareaField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = 'mb-2',
  required = false,
  ...props
}: TextareaFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <textarea
        {...field}
        {...props}
        className={`form-control ${error && touch ? 'is-invalid' : ''}`}
        value={field.value ?? ''}
      />
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

type SelectFieldProps = FieldProps &
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  }

export const SelectField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = 'mb-2',
  required = false,
  children,
  onChange,
  ...props
}: SelectFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <select
        {...field}
        {...props}
        className={`form-select ${error && touch ? 'is-invalid' : ''}`}
        value={field.value ?? ''}
        onChange={(e) => {
          field.onChange(e)
          onChange && onChange(e)
        }}
      >
        {children}
      </select>
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

interface ImagePreviewProps {
  value: string | File
}

function isUrl(url: string) {
  try {
    new URL(url)
    return true
  } catch (_) {
    return false
  }
}

function ImagePreview({ value }: ImagePreviewProps) {
  const [url, setUrl] = useState<string | null>(null)

  useEffect(() => {
    // Got a file
    if (value instanceof File) {
      const objUrl = URL.createObjectURL(value)
      setUrl(objUrl)
      return () => {
        URL.revokeObjectURL(objUrl)
      }
    }
    // Got a string?
    if (isUrl(value)) {
      // Good url!
      setUrl(value)
    } else {
      // Bad url
      setUrl(null)
    }
  }, [value])

  if (url === null) {
    return null
  }

  return (
    <div className="mt-2">
      <img
        style={{ maxHeight: 100 }}
        alt="Upload preview"
        src={url}
        className="img-thumbnail"
      />
    </div>
  )
}

interface LinkPreviewProps {
  value: string | File
}

function LinkPreview({ value }: LinkPreviewProps) {
  if (typeof value === 'string' && isUrl(value)) {
    return (
      <a href={value} target="_blank" rel="noreferrer">
        <i className="bi bi-link" />
        <small> file</small>
      </a>
    )
  }
  return null
}

function AudioPreview({ value }: LinkPreviewProps) {
  if (typeof value === 'string' && isUrl(value)) {
    return (
      <audio
        className="mt-2"
        style={{ height: 40, width: '100%' }}
        src={value}
        controls
        autoPlay={false}
      />
    )
  }
  return null
}

type FileFieldProps = FieldProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  } & {
    fileType?: string
  }

export const FileField = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  fileType,
  required = false,
  containerClassName = 'mb-2',
  ...props
}: FileFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const inputRef = useRef<HTMLInputElement>(null)

  let acept: string | undefined = undefined
  if (fileType === 'image') {
    acept = 'image/*'
  } else if (fileType === 'audio') {
    acept = 'audio/*'
  } else if (fileType === 'video') {
    acept = 'video/*'
  } else if (fileType === 'pdf') {
    acept = 'application/pdf'
  }

  // Reset name on input when value is an uploaded url
  useEffect(() => {
    if (typeof field.value === 'string' && inputRef.current) {
      inputRef.current.value = ''
    }
  }, [field.value])

  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <input
        {...field}
        ref={inputRef}
        accept={acept}
        className={`form-control ${error && touch ? 'is-invalid' : ''}`}
        {...props}
        value={undefined}
        type="file"
        onChange={(e) => setFieldValue(field.name, e.target.files?.[0])}
      />
      {(() => {
        if (fileType === 'image') {
          return <ImagePreview value={field.value} />
        } else if (fileType === 'audio') {
          return <AudioPreview value={field.value} />
        } else {
          return <LinkPreview value={field.value} />
        }
      })()}
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

type DateFieldProps = FieldProps & {
  label?: string
  required?: boolean
}

export const DateField = ({
  label,
  field,
  form,
  required = false,
  ...props
}: DateFieldProps) => {
  let selected = null
  if (field.value) {
    const date = new Date(field.value)
    selected = isNaN(date.getTime()) ? null : date
  }

  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  const className = `form-control ${touch && error ? 'is-invalid' : ''}`

  return (
    <div className="form-group mb-2">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <div>
        <DatePicker
          {...props}
          autoComplete="off"
          className={className}
          showYearDropdown
          locale={'it'}
          showMonthDropdown
          scrollableYearDropdown
          dropdownMode="select"
          yearDropdownItemNumber={5}
          wrapperClassName="w-100"
          placeholderText="dd/mm/yyyy"
          name={field.name}
          selected={selected}
          onBlur={field.onBlur}
          dateFormat="dd/MM/yyyy"
          // dateFormat='dd/MM/yyyy'
          onChange={(date) => {
            if (!date || date.toDateString() === 'Invalid Date') {
              form.setFieldValue(field.name, '')
            } else {
              form.setFieldValue(field.name, format(date, 'yyyy-MM-dd'))
            }
          }}
        />
      </div>
      {error && touch && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  )
}

type CheckBoxFieldProps = FieldProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  }

export const CheckBoxField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = 'mb-2',
  required = false,
  ...props
}: CheckBoxFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value, ...passField } = field
  return (
    <div className={`form-check ${containerClassName}`}>
      <input
        type="checkbox"
        {...passField}
        {...props}
        className={`form-check-input ${error && touch ? 'is-invalid' : ''}`}
        checked={field.value === true}
      />
      {label && (
        <label className="form-check-label" htmlFor={field.name}>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

type StringListFieldProps = FieldProps<string[]> & {
  label?: string
}

const StringListFieldReactSelectComponents = {
  DropdownIndicator: null,
}

interface RSOption {
  label: string
  value: string
}

export function StringListField({ field, form, label }: StringListFieldProps) {
  const selectValue = useMemo(
    () => field.value.map((v) => ({ label: v, value: v })),
    [field.value]
  )

  const [inputValue, setInputValue] = useState('')

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (!inputValue) return
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          form.setFieldValue(
            field.name,
            uniq(field.value.concat(inputValue.toLowerCase()))
          )
          setInputValue('')
          event.preventDefault()
      }
    },
    [field.name, field.value, form, inputValue]
  )

  const handleChange = useCallback(
    (value: OnChangeValue<RSOption, true>) => {
      form.setFieldValue(
        field.name,
        value.map((v) => v.value)
      )
    },
    [field.name, form]
  )

  return (
    <div className="form-group mb-2">
      {label && <label>{label}</label>}
      <CreatableSelect
        classNamePrefix="hm-rs"
        components={StringListFieldReactSelectComponents}
        onBlur={field.onBlur}
        onKeyDown={handleKeyDown}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onChange={handleChange}
        value={selectValue}
        isClearable
        isMulti
        placeholder="Scrivi del testo e premi invio per aggiungere un valore"
        menuIsOpen={false}
      />
    </div>
  )
}

type MultiSelectFieldProps = FieldProps<any[]> & {
  label?: string
  options: RSOption[]
}

interface RSOption {
  label: string
  value: string
}

export function MultiSelectField({
  field,
  form,
  label,
  options,
}: MultiSelectFieldProps) {
  const selectValue = useMemo(
    () =>
      field.value ? options?.filter((d) => field.value.includes(d.value)) : [],
    [field.value, options]
  )

  const [inputValue, setInputValue] = useState('')

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (!inputValue) return
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          form.setFieldValue(
            field.name,
            uniq(field.value.concat(inputValue.toLowerCase()))
          )
          setInputValue('')
          event.preventDefault()
      }
    },
    [field.name, field.value, form, inputValue]
  )

  const handleChange = useCallback(
    (value: OnChangeValue<RSOption, true>) => {
      form.setFieldValue(
        field.name,
        value.map((v) => v.value)
      )
    },
    [field.name, form]
  )

  return (
    <div className="form-group mb-2">
      {label && <label>{label}</label>}
      <Select
        classNamePrefix="hm-rs"
        // components={MultiFieldReactSelectComponents}
        onBlur={field.onBlur}
        onKeyDown={handleKeyDown}
        // inputValue={inputValue}
        onInputChange={setInputValue}
        onChange={handleChange}
        value={selectValue}
        options={options}
        name={field.name}
        noOptionsMessage={() => 'Nessuna keyword'}
        isSearchable
        isClearable
        isMulti
        placeholder="Seleziona le keywords della clip"
        // menuIsOpen={false}
      />
    </div>
  )
}

type ReactSelectFieldProps = FieldProps<any> & {
  label?: string
  options: RSOption[]
  placeholder?: string
}

export function ReactSelectField({
  field,
  form,
  label,
  options,
  placeholder,
}: ReactSelectFieldProps) {
  const [inputValue, setInputValue] = useState('')

  const selectValue = useMemo(() => {
    return options.filter((o) => field.value === o.value)
  }, [options, field.value])

  const handleChange = useCallback(
    (value: OnChangeValue<RSOption, false>) => {
      form.setFieldValue(field.name, value?.value)
    },
    [field.name, form]
  )

  return (
    <div className="form-group mb-2">
      {label && <label>{label}</label>}
      <Select
        classNamePrefix="hm-rs"
        // components={MultiFieldReactSelectComponents}
        onBlur={field.onBlur}
        // inputValue={inputValue}
        className="react-select-mono"
        isMulti={false}
        onInputChange={setInputValue}
        onChange={handleChange}
        value={selectValue}
        options={options}
        name={field.name}
        isSearchable
        isClearable
        placeholder={placeholder ? placeholder : 'Seleziona...'}
        // menuIsOpen={false}
      />
    </div>
  )
}

type MapFieldProps = FieldProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  }

function DraggableMarker({
  center,
  setCenter,
}: {
  center: LatLngTuple
  setCenter: any
}) {
  const [draggable, setDraggable] = useState(true)
  const [position, setPosition] = useState(center)
  const markerRef = useRef<any>(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
          setCenter(marker.getLatLng())
        }
      },
    }),
    [setCenter]
  )

  useEffect(() => {
    if (center[0] !== position[0] || center[1] !== position[1]) {
      setPosition(center)
    }
  }, [center, setPosition])

  // const toggleDraggable = useCallback(() => {
  //   setDraggable((d) => !d)
  // }, [])

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    ></Marker>
  )
}

const DEFAULT_CENTER: LatLngTuple = [44, 11]

function ChangeView({ center, zoom }: { center: any; zoom: any }) {
  const map = useMap()
  map.setView(center, zoom)
  return null
}

export const MapField = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  containerClassName = 'mb-2',
  required = false,
  onChange,

  ...props
}: MapFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  const coordinates = useMemo(() => {
    if (!field.value) {
      return DEFAULT_CENTER
    }
    return [
      field.value.coordinates[1],
      field.value.coordinates[0],
    ] as LatLngTuple
  }, [field.value])

  const setCoordinates = useCallback(
    (value: any) => {
      setFieldValue(field.name, {
        type: 'Point',
        coordinates: [value.lng, value.lat],
      })
    },

    [field.name, setFieldValue]
  )

  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <MapContainer
        center={coordinates}
        zoom={10}
        style={{ width: '100%', height: 400 }}
        scrollWheelZoom={false}
      >
        <ChangeView center={coordinates} zoom={10} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker setCenter={setCoordinates} center={coordinates} />
      </MapContainer>
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}
