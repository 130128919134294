import { Link, useNavigate, useParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BlockHome } from '../../types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Page from '../../components/Page'
import Header from '../../components/Header'
import { useBlockHome, useDeleteBlockHome, useUpdateBlockHome } from '../../hooks/blocks-home'
import BlockHomeForm from '../../components/BlockHomeForm'

export default function BlocksHomePageEdit() {
  const { id } = useParams()
  const { data: block } = useBlockHome(+id!)
  const deleteBlock = useDeleteBlockHome()
  const updateBlock = useUpdateBlockHome()
  const [modalRm, modalRmActions] = useModalTrigger<BlockHome>()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/blocks-home-page/">Blocchi Home Page</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {block!.title.it}
            </li>
          </ol>
        </nav>
        <button
          className="btn  btn-danger"
          onClick={() => modalRmActions.open(block)}
        >
          <i className="bi bi-trash3-fill"></i> Elimina
        </button>
      </Header>

      <div className="py-3 overflow-y-auto flex-1">
        <BlockHomeForm
          block={block}
          save={(blockHome) =>
            updateBlock.mutateAsync(blockHome).then(() =>
              toast.success('Blocco salvato con successo!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              })
            )
          }
        />
      </div>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare il blocco {modalRm.value.title.it} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btnoutline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteBlock.isLoading}
            onClick={async () => {
              await deleteBlock.mutateAsync(modalRm.value!.id)
              navigate('/contents')
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
