import classNames from 'classnames'
import { Field, Formik } from 'formik'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as Yup from 'yup'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import {
  useCreateBlockVitaArchivio,
  useDeleteBlockVitaArchivio,
  useUpdateBlockVitaArchivio,
} from '../hooks/vite-archivio'
import { BaseVitaArchivio, BlockVitaArchivio, VitaArchivio } from '../types'
import {
  FileField,
  InputField,
  TextareaField,
  transformErrorForForm,
} from './form'
import ModalBlockVitaArchivio from './ModalBlockVitaArchivio'

const ViteArchivioSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),
  description: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Descrizione')]))
  ),
  cover_image: Yup.mixed().nullable().label('Immagine di copertina'),
})

interface Props {
  vita?: VitaArchivio
  save: (vita: VitaArchivio) => Promise<unknown>
  refetch: any
}

export const initialThemeValues: BaseVitaArchivio = {
  title: EMPTY_LANG_FIELD,
  description: EMPTY_LANG_FIELD,
  cover_image: null,
  blocks: [],
}

export default function VitaArchivioForm({ vita, save, refetch }: Props) {
  const [lang, setLang] = useState('en')
  const deleteBlockVitaArchivio = useDeleteBlockVitaArchivio()
  const updateBlockVitaArchivio = useUpdateBlockVitaArchivio()
  const addBlockVitaArchivio = useCreateBlockVitaArchivio()
  const [modalRm, modalRmActions] = useModalTrigger<BlockVitaArchivio>()
  const [modalAdd, modalAddActions] = useModalTrigger()
  const [modalUpdate, modalUpdateActions] = useModalTrigger<BlockVitaArchivio>()

  return (
    <Formik
      enableReinitialize
      onSubmit={(vita, { setErrors }) =>
        save(vita).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={ViteArchivioSchema}
      initialValues={(vita ?? initialThemeValues) as VitaArchivio}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div>
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Titolo [it]`}
                name={`title.it`}
                component={InputField}
              />
              <Field
                label={`Descrizione [it]`}
                name={`description.it`}
                rows={5}
                component={TextareaField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Titolo [${lang}]`}
                name={`title.${lang}`}
                component={InputField}
              />
              <Field
                label={`Descrizione [${lang}]`}
                name={`description.${lang}`}
                rows={5}
                component={TextareaField}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4 mt-4">
              <h4>Informazioni aggiuntive</h4>
            </div>
            <div className="col-md-6">
              <Field
                label="Immagine di copertina"
                component={FileField}
                fileType="image"
                name="cover_image"
              />
            </div>
          </div>
          {vita?.id && (
            <div className="mt-3">
              <div className="d-flex justify-content-between">
                <h4>Blocchi contenuto</h4>
                <Button color="primary" onClick={() => modalAddActions.open()}>
                  <i className="bi bi-plus-circle me-2"></i> Aggiungi blocco
                </Button>
              </div>
              <table className="table">
                <thead>
                  <th>Titolo</th>
                  <th>Tipo</th>
                  <th>Ordine</th>
                  <th></th>
                </thead>
                <tbody>
                  {vita?.blocks?.map((block) => (
                    <tr key={block.id}>
                      <td>{block.title.it}</td>
                      <td>
                        {block.type === 'text-slider'
                          ? 'Blocco Slider'
                          : 'Blocco Timeline'}
                      </td>
                      <td>{block.order}</td>
                      <td>
                        <i
                          onClick={() => modalUpdateActions.open(block)}
                          className="bi cursor-pointer me-3 text-primary bi-pencil-fill"
                        ></i>
                        <i
                          onClick={() => modalRmActions.open(block)}
                          className="bi cursor-pointer text-danger bi-trash3-fill"
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
          {vita?.id && (
            <>
              <Modal
                centered
                isOpen={modalRm.isOpen}
                toggle={modalRmActions.toggle}
              >
                <ModalHeader toggle={modalRmActions.toggle}>
                  Elimina
                </ModalHeader>
                <ModalBody>
                  {modalRm.value && (
                    <div className="pt-3 pb-3">
                      Sei sicuro di voler eliminare il blocco vita d'archivio{' '}
                      {modalRm.value.title[MAIN_LANG]} ?
                    </div>
                  )}
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                  <button
                    onClick={modalRmActions.toggle}
                    className="btn btn-outline-primary"
                  >
                    Annulla
                  </button>
                  <button
                    className="btn  btn-danger"
                    disabled={deleteBlockVitaArchivio.isLoading}
                    onClick={async () => {
                      await deleteBlockVitaArchivio.mutateAsync(
                        modalRm.value!.id
                      )
                      modalRmActions.close()
                    }}
                  >
                    <i className="bi bi-trash3-fill"></i> Elimina
                  </button>
                </ModalFooter>
              </Modal>
              <ModalBlockVitaArchivio
                isOpen={modalUpdate.isOpen}
                block={modalUpdate?.value || undefined}
                toggle={modalUpdateActions.toggle}
                save={async (block) => {
                  await updateBlockVitaArchivio.mutateAsync(block)
                  modalUpdateActions.toggle()
                  refetch()
                }}
                vita={vita.id}
              />
              <ModalBlockVitaArchivio
                isOpen={modalAdd.isOpen}
                toggle={modalAddActions.toggle}
                save={async (block) => {
                  await addBlockVitaArchivio.mutateAsync(block)
                  modalAddActions.toggle()
                  refetch()
                }}
                vita={vita.id}
              />
            </>
          )}
        </form>
      )}
    </Formik>
  )
}
