import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { PaginatedDJResponse, Territorio } from '../types'
import { makeFormDataEncoder } from './utils'

export function useTerritories(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Territorio>>(
    ['territories', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/territories/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useTerritorio(id: number) {
  return useQuery<Territorio>(
    ['territory', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/territories/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteTerritorio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/territories/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['territories'])
        client.invalidateQueries(['territory'])
      },
    }
  )
}

const makeTerritorioFormData = makeFormDataEncoder({
  fileFields: [],
  excludeFields: [],
})

export function useCreateTerritorio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (territorio: Territorio) =>
        axios
          .post(`/api/admin/territories/`, makeTerritorioFormData(territorio), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Territorio)
    ),
    {
      onSuccess(savedTerritorio) {
        client.invalidateQueries(['territories'])
        client.setQueryData(['territory', savedTerritorio.id], savedTerritorio)
      },
    }
  )
}

export function useUpdateTerritorio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (territorio: Territorio) =>
        axios
          .put(
            `/api/admin/territories/${territorio.id}/`,
            makeTerritorioFormData(territorio),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Territorio)
    ),
    {
      onSuccess(savedTerritorio) {
        client.invalidateQueries(['territories'])
        client.setQueryData(['territory', savedTerritorio.id], savedTerritorio)
      },
    }
  )
}
